<template>
  <div class="section">
    <div
      v-if="index === 0"
      id="mapContainer1"
      class="map-container"
    />
    <div
      v-if="index === 0"
      class="tabBar-class"
      @click="tabBarToggle(1)"
    >
      <iconpark-icon
        name="quanbu"
        class="tabBar-icon"
      ></iconpark-icon>
      <span class="tabBar-text">所有客户</span>
    </div>
    <div
      v-if="index === 1"
      class="tabBar-other"
      @click="tabBarToggle(0)"
    >
      <img
        src="@/assets/img/icon_location@2x.png"
        class="tabBar-img"
      />
      <span
        v-if="!isScrollExceedScreen"
        class="tabBar-text"
      >附近客户</span>
    </div>
    <new-search
      v-if="index === 0"
      v-model="requestData.custCn"
      search-type="titleName"
      :placeholder="placeholder"
      @getLists="searchList"
    />
    <new-search
      v-if="index === 1"
      v-model="allListParams.custCn"
      search-type="titleName"
      :placeholder="placeholder"
      @getLists="searchList"
    />
    <div
      ref="mapList"
      class="map-list"
      :class="index === 0 ? 'scroll-list-wrap' : 'scroll-list-wraps' "
    >
      <!-- 附近客户 -->
      <cube-scroll
        ref="scroll"
        :scroll-events="['scroll']"
        :options="options"
        :data="index === 1 ? tabList : []"
        @pulling-down="onPullingDown"
        @pulling-up="onPullingUp"
        @scroll="onCubeScroll"
      >
        <div
          v-if="index === 0"
          class="list-wrapper"
        >
          <error-page
            v-if="nearBloon && !nearbyList.length"
          />
          <ul class="map-ul">
            <li
              v-for="(item, i) in nearbyList"
              :key="i"
              class="map-item border-bottom-1px"
              @click="selectCustomer(1, item, i)"
            >
              <div class="info">
                <div
                  class="name"
                  v-html="item.custCnLight"
                />
                <div class="address">
                  <span class="item-addr">{{ item.province }}{{ item.city }}{{ item.area }}{{ item.addrStreet }}</span>
                </div>
              </div>
              <i
                class="iconfont icon-joyo edit-cust"
                @click.stop="toEditCustAddress(item)"
              >
                &#xe6eb;
              </i>
            </li>
          </ul>
        </div>
        <!-- 所有客户 -->
        <div
          v-if="index === 1"
          class="list-wrapper"
        >
          <error-page
            v-if="allBloon && !allList.length"
          />
          <ul class="map-ul">
            <li
              v-for="(item, i) in allList"
              :key="i"
              class="map-item border-bottom-1px"
              @click="selectCustomer(2, item, i)"
            >
              <div class="info">
                <div
                  class="name"
                  v-html="item.custCnLight"
                />
                <div class="address">
                  <span class="item-addr">{{ item.province }}{{ item.city }}{{ item.area }}{{ item.addrStreet }}</span>
                </div>
              </div>
              <i
                class="iconfont icon-joyo edit-cust"
                @click.stop="toEditCustAddress(item)"
              >
                &#xe6eb;
              </i>
            </li>
          </ul>
        </div>
      </cube-scroll>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import AMapLoader from '@amap/amap-jsapi-loader';
import errorPage from '_c/errorPage';
import newSearch from '_c/search/search-model';

import { debounce } from '@/lib/until';
import {
  getCustomermanagement,
  getCustomermanagementList
} from '../../api/sign';
import config from '../../lib/config';

export default {
  components: {
    newSearch,
    errorPage
  },
  data() {
    return {
      map: null,
      index: 0,
      timer: null,
      tableData: [],
      tabList: [],
      nearBloon: false,
      allBloon: false,
      customerList: [],
      position: {
        lng: 120.24066,
        lat: 30.23907
      }, // 定位信息,
      keywords: '搜索关键字',
      showSearchCustomer: false,
      page: 1,
      pageSize: 10,
      customerCount: 0,
      allList: [],
      nearbyList: [],
      checkAddr: '',
      requestData: {
        pageNum: 1,
        pageSize: 20,
        custCn: ''
      },
      allListParams: {
        pageNum: 1,
        pageSize: 20,
        custCn: '',
        selleUserId: this.$store.state.userInfo.userInfoRes.staffId
      },
      nearByCustomerIndex: null,
      allCustomerIndex: null,
      fixedHeights: '',
      // crm1.9.3 scroll新增
      pullDownRefreshThreshold: 60,
      pullDownRefreshTxt: '刷新成功',
      pullUpLoadThreshold: 0,
      pullUpLoadMoreTxt: '更多~',
      pullUpLoadNoMoreTxt: '到底了~',
      isScrollExceedScreen: false // 滚动超过一屏
    };
  },
  computed: {
    loadEnable() {
      if (this.index === 0) {
        return false;
      } else {
        if (this.allList.length > 0) {
          if (this.allList.length < 20) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    },
    // scroll 相关；
    options() {
      return {
        pullDownRefresh: this.pullDownRefreshObj,
        pullUpLoad: this.pullUpLoadObj,
        scrollbar: true
      }
    },
    pullDownRefreshObj() {
      return this.loadEnable ? {
        threshold: parseInt(this.pullDownRefreshThreshold),
        txt: this.pullDownRefreshTxt
      } : false
    },
    pullUpLoadObj() {
      return this.loadEnable ? {
        threshold: parseInt(this.pullUpLoadThreshold),
        txt: {
          more: this.pullUpLoadMoreTxt,
          noMore: this.pullUpLoadNoMoreTxt
        }
      } : false
    },
    placeholder() {
      return this.index === 0 ? `在 ${this.$store.state.sign.custName} 附近搜索客户` : '请输入客户名称';
    },
    ...mapGetters('cust', ['aidList'])
  },
  watch: {
    index: {
      handler(newV) {
        if (newV === 0) {
          this.fixedHeights = true;
        } else {
          this.fixedHeights = false;
        }
      },
      deep: true
    }
  },
  created() {
    this.geolocation = null;
    // 保存回来之后的对应的列表的下标
    if (this.$route.query.index) {
      this.index = Number(this.$route.query.index);
      this.tabBarToggle(this.index);
    }
  },
  mounted() {
    this.loadMap();
    this.countHeights();
  },
  // updated() {
  //   this.countHeights();
  // },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapMutations('sign', {
      setCustomer: 'SET_CUSTOMER',
      setAddress: 'SET_ADDRESS'
    }),
    countHeights() {
      this.$nextTick().then(() => {
        this.fixedHeights = 240 + '';
      });
    },
    searchList(value) {
      if (this.index === 0) {
        this.nearbyList = [];
        this.getNearbyCustomer(value);
      } else {
        this.allList = [];
        this.allListParams.pageNum = 1;
        this.allListParams.selleUserId = '';
        this.getCustomerList();
      }

      this.nearByCustomerIndex = null;
      this.allCustomerIndex = null;
      this.showSearchCustomer = false;
    },
    getIndex(index) {
      this.index = index;
      if (this.index === 0) {
        this.nearBloon = false;
        this.nearbyList = [];
        this.requestData.custCn = '';
      } else {
        this.allBloon = false;
        this.allList = [];
        this.allListParams.pageNum = 1;
        this.allListParams.custCn = '';
        this.allListParams.selleUserId = this.$store.state.userInfo.userInfoRes.staffId;
      }
      const changelist = debounce(this.changeCustomerList, 500);
      changelist();
    },
    changeCustomerList() {
      if (this.index === 0) {
        this.getNearbyCustomer();
      } else {
        this.getCustomerList();
      }
    },
    reLocation() {
      this.geolocation.getCurrentPosition((status) => {
        if (status === 'complete') {

        }
      });
    },
    selectCustomer(type, item, i) {
      if (type === 1) {
        // 附近客户
        this.nearByCustomerIndex = i;
      } else if (type === 2) {
        // 所有客户
        this.allCustomerIndex = i;
      }
      // 用于判断打卡点；
      this.setCustomer(item);
      this.confirmCustomer()
    },
    // 附近客户
    getNearbyCustomer(value = '') {
      this.nearBloon = false;
      this.$loading.show();
      getCustomermanagement(this.position.lng, this.position.lat, '3000', value).then(
        res => {
          this.$loading.hide();
          if (res.flag) {
            this.nearBloon = true;
            if (res.data && res.data.detailList) {
              const nearbyList = [...res.data.detailList];
              // 显示高亮
              let replaceReg = '';
              let replaceString = '';
              nearbyList.forEach(item => {
                if (value !== '') {
                  replaceReg = new RegExp(value, 'ig')
                  replaceString = `<span style="color: #00A4FF">${value}</span>`
                }
                item.custCnLight = item.custCn.replace(replaceReg, replaceString)
              })
              this.nearbyList = nearbyList;
            }
          } else {
            this.$showToast(res.errorMsg);
          }
        }
      );
    },
    // 所有客户
    getCustomerList(type = '') {
      this.allBloon = false;
      this.$loading.show();
      getCustomermanagementList(this.allListParams).then(res => {
        this.$loading.hide();
        if (res.flag) {
          this.allBloon = true;
          if (res.data && res.data.pageInfo && res.data.pageInfo.list) {
            let tableData = [];
            const list = res.data.pageInfo.list || [];
            if (list.length > 0) {
              if (type === 'upload') {
                tableData = this.allList.concat(list);
              } else {
                tableData = list;
              }
              this.tabList = tableData;
              this.allList = tableData;
              this.allList.forEach(item => {
                const replaceReg = new RegExp(this.allListParams.custCn, 'ig')
                const replaceString = `<span style="color: #00A4FF">${this.allListParams.custCn}</span>`
                item.custCnLight = item.custCn.replace(replaceReg, replaceString)
              })
              this.upload = true;
            } else {
              this.tabList = []
              this.upload = false
            }
          }
        } else {
          this.$loading.hide();
          this.$showToast(res.errorMsg);
        }
      });
    },
    refreshLoad(type) {
      if (this.index === 1) {
        this.allListParams.pageNum += 1;
      }
      this.getCustomerList(type);
    },
    confirmCustomer() {
      this.$router.go(-1);
      // this.$router.replace({ name: 'AddSigns' })
    },
    loadMap() {
      const self = this;
      // 解析定位结果
      function onComplete(AMap, result) {
        self.position = result.position;
        self.checkAddr = result.formattedAddress || '';
        self.getIndex(self.index)
      }
      // 解析定位错误信息
      function onError(data) {
        self.nearBloon = true;
        return data;
      }

      AMapLoader.load({
        key: config.MAP_KEY, // 申请好的Web端开发者Key，首次调用 load 时必填
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1', // AMapUI 缺省 1.1
          plugins: [] // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: '1.3.2' // Loca 版本，缺省 1.3.2
        }
      })
        .then(AMap => {
          this.map = new AMap.Map('mapContainer1', { resizeEnable: true });

          AMap.plugin('AMap.Geolocation', () => {
            this.geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, // 是否使用高精度定位，默认:true
              timeout: 10000, // 超过10秒后停止定位，默认：5s
              showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
              showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
              showButton: true, // 显示定位按钮，默认：true
              buttonPosition: 'RT', // 定位按钮停靠位置，默认：'LB'，左下角
              buttonOffset: new AMap.Pixel(16, 16), // 定位按钮位置
              panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
              zoomToAccuracy: true // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            });

            this.map.addControl(this.geolocation);
            this.geolocation.getCurrentPosition((status, result) => {
              if (status === 'complete') {
                onComplete(AMap, result);
              } else {
                onError(result);
              }
            });
          });
        });
    },
    destroyMap() {
      this.map && this.map.destroy();
    },
    // crm1.9.2新增
    // 跳转修改客户地址页
    toEditCustAddress(data) {
      // 存储当前选中的客户地址
      this.setAddress(data);
      this.$router.push(
        {
          name: 'EditCustAddress',
          params: {
            id: data.id,
            custCn: data.custCn,
            index: this.index
          }
        }
      )
    },
    // crm1.9.3改动
    // 切换
    tabBarToggle(index) {
      this.index = index;
      if (index === 0) {
        this.loadMap()
      } else {
        this.destroyMap()
      }
      this.getIndex(index)
    },
    // 搜索规则
    // ruleFunc(value) {
    //   if (value === '') return false;
    //   if (value.length < 4) {
    //     this.$showToast('不得少于四个字');
    //     return false;
    //   }
    //   return true;
    // }
    // 下拉刷新
    onPullingDown() {
      this.allListParams.pageNum = 1;
      this.getCustomerList();
      // this.$refs.scroll.forceUpdate()
    },
    // 上拉加载
    onPullingUp() {
      if (this.upload) {
        this.refreshLoad('upload')
      } else {
        this.$refs.scroll.forceUpdate()
      }
    },
    // 滚动
    onCubeScroll(val) {
      if (!this.scrollEvent) {
        this.scrollEvent = {
          timer: null,
          isRun: false
        }
      }
      if (this.scrollEvent.isRun) return
      this.scrollEvent.isRun = true
      clearTimeout(this.scrollEvent.timer)
      this.scrollEvent.timer = setTimeout(() => {
        this.scrollEvent.isRun = false
        this.isScrollExceedScreen = Math.abs(val.y) >= this.$refs.mapList.offsetHeight
      }, 50)
    }
  }
};
</script>

<style lang="scss" src="@/assets/css/components/btn-bottom.scss"></style>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 240px;
}
.address-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 16px;
  font-weight: 400;
  line-height: 1.5;
  background: $color-FFF;
  .text {
    color: $color-333;
    font-size: 15px;
    text-align: left;
  }
  .button {
    color: $color-theme;
    font-size: 14px;
  }
}

.search-box {
  padding: 15px;
  background: $color-FFF;
  .search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 34px;
    padding: 0 10px;
    color: $color-CCC;
    font-size: 14px;
    background: $color-F4;
    border: 1px solid $color-E6;
    border-radius: 17px;
    .icon-joyo {
      margin-right: 10px;
    }
  }
}
.map-list{
  // height: 350px;
  height: calc(100% - 240px - 58px);
  overflow: hidden;
  transform: rotate(0deg); // fix 子元素超出边框圆角部分不隐藏的问题
}
.scroll-list-wrap {
  height: calc(100% - 250px - 58px);
}
.scroll-list-wraps {
  height: calc(100% - 58px);
}
.list-wrapper {
  min-height: 74px;
  background: $color-FFF;
  .map-ul{
    margin: 0 15px;
  }
  .map-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    padding: 14px 0;
    .edit-cust {
      flex:0 0 20px;
      width: 20px;
      // height: 40px;
      padding-left:12px ;
      color: #C6C7CC;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
    }

    .info {
      flex: 1;
      text-align: left;
      .name {
        margin-bottom: 4px;
        color: #23252E;
        font-size: 15px;
        line-height: 20px;
      }
      .address {
        display: flex;
        color: #999BA3;
        font-size: 12px;
        line-height: 16px;
        .item-addr{
          flex: 1;
          @extend .uniline-ellipsis;
        }
      }
    }
  }
}
.tabBar-class{
  position: absolute !important;
  top: 184px;
  right: 15px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 2px 0 15px;
  background: $color-FFF !important;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
}
.tabBar-other{
  position: absolute !important;
  right: 15px;
  bottom: 64px;
  z-index: 200;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  padding: 4px;
  background: $color-FFF !important;
  border-radius: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
}
.tabBar-icon{
  font-size: 20px;
  // vertical-align: middle;
}
.tabBar-text{
  padding-right: 10px;
  padding-left: 6px;
  color: #23252E;
  font-size: 12px;
  line-height: 16px;
}
.tabBar-img{
  width: 32px;
  height: 32px;
  // margin-left: 4px;
}
</style>
